import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/top 1.png'
import './nav.css'


function CollapsibleExample() {
  return (
      <>
    <Navbar collapseOnSelect expand="lg"  variant="dark" style={{backgroundColor:'rgba(4, 34, 65)'}}>
      <Container >
        <Navbar.Brand href="/"><img src={logo} alt="Logo" style={{height:'80px',width:'220px',marginTop:'15px',marginBottom:'10px'}}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='me-auto'></Nav>
          <Nav>
          <div className='about underline-small'  style={{padding:'40px',color:'white'}}>
              <a href='/about' >ABOUT US</a>
          </div>
          <div className='part' style={{padding:'40px',color:'white'}}>
              <a href='/part' >PARTNERSHIP</a>
          </div>
          <div className='part' style={{padding:'40px',color:'white'}}>
              <a href='https://docs.google.com/document/d/1FAqYHq6PgYmZfKQMbJ70GaE75jBdQrU55dOHn8LXl9Q/edit?usp=sharing' target={'_blank'}>CHALLENGES</a>
          </div>
            
            <Nav.Link href='https://forms.gle/L6Yu1pabmK4T4xqX8' target={'_blank'}><button className='apply' >APPLY NOW</button></Nav.Link>
            
          </Nav>
  
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    </>
  );
}

export default CollapsibleExample;