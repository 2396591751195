import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes,Route,Navigate} from 'react-router-dom';
import About from './components/About';
import Partnership from './components/Partnership';
import Home from './components/Home';


function App() {
  return (
    <div className="App">
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/about'  element={<About/>}/>
            <Route path='/part' element={ <Partnership/>}/>
          </Routes>
     
      {/* <CollapsibleExample/> */}

     
      {/* <img src={pic1} alt="pic1" style={{width:'100%',height:'auto'}} />

      
      <div className='sus'>
        <div className='left' >
        <img src={line} alt="" srcset="" style={{height:'280px'}}></img>
        </div>
          <div className='right'>
            <h2>HACK THE CLIMATE</h2>
            <p>
                      BuzzOnEarth India Hackathon -2023 welcomes participation from colleges from all across
                the country to find solutions to some of the most pressing climate and sustainability issues
                that Indian businesses and communities face. The solutions can be combinations of tech
                products, policy evaluations, market models, business plans, new designs and product
                innovations. Give wings to your creativity and ideas and develop innovative solutions that
                can be deployed. This is an Open Innovation Student category in which students can give
                ideas against the pre-defined Problem Statements across themes.
            </p>
          </div>
    </div>


    <h1 className='fade_themes'>
      THEMES
    </h1>
              <h1 className='themes'>
                THEMES
              </h1>
        
      <div className="imggal">
          <div className='left_div'>
          <div class="gallery dot_bo" >
          
          <img src={cons} alt="" srcset="" />
        
          <div class="desc">CONSTRUCTION AND INFRASTRUCTURE</div>
        </div>

        <div class="gallery dot_bo ">
          
          <img src={food} alt="" srcset=""/>

          <div class="desc">FOOD AND AGRICULTURE</div>
        </div>

        <div class="gallery dot_bo">
          
          <img src={tech} alt="" srcset="" />
          
          <div class="desc">TECHNOLOGY AND IOT</div>
        </div>
          </div>
          
        <div className='right_div'>
          <div class="gallery dot_bo">
            
            <img src={fas} alt="" srcset="" />
          
            <div class="desc">FASHION AND SPORTS</div>
          </div>
          <div class="gallery dot_bo">
            
            <img src={tour} alt="" srcset="" />
            
            <div class="desc">TOURISM AND ENTERTAINMENT</div>
          </div>
          <div class="gallery dot_bo ">
            
            <img src={ener} alt="" srcset="" />
            
            <div class="desc">ENERGY AND MOBILITY</div>
          </div>
          </div>
          </div>
   
      
    
        <div className='reg'>
            <h1>
              REGISTRATION PROCESS
            </h1>
            <p className='how'>
            How do I register for BuzzOnEarth India Hackathon 2023?
            </p>
            <p className='info'>
            Students can directly register themselves. If you are a student, visit https://www.buzzonearth/boe.in/ for registration. However, you need to have the authorisation
            letter from your respective colleges.
            </p>
            <h1>
            TEAM FORMATION
          </h1>
          <div className='info1'>
          <ul>
            <li>All team members should be from same college (recommended).</li>
            <li>Inter-college teams are also allowed; provided the selected teams for the grand finale submit authorization letters from their respective colleges.</li>
            <li>Members from different branches are encouraged to form a team.</li>
            <li>Each team should mandatorily comprise of 2-6 members including the team leader.</li>
            <li>Each team must have at least one female team member.</li>
            <li>The team should have multi disciplinary skill sets. That should be well versed with programming skills, a good mix of Mechanical Engineers, Electronic Engineers,
          Product Designers and other disciplines.</li>
          </ul>
          </div>
        </div>
        <div className='mode_of' >
          <img src={mode_of5} alt="pic2" className='mode_of5'  />
          <img src={mode_of6} alt="pic3" className='mode_of6'  />
        </div>
        <div className='prob'>
          <h1>
          PROBLEM STATEMENTS AND PRIZES TO BE WON
              </h1>
              <p className='info'>
              <ul>
                <li>BuzzOnEarth India Hackathon 2023 brings you problem statements from leading public and private organizations, union ministries, state governments and NGOs in
                India.</li>
                <li>There would be Top 3 winning team for every problem statement posted on the portal. The prize money will be given by the collaborating ministry/ industry ONLY
                IF that organization likes the idea of the winning team. If the collaborating ministry/ industry conclude that none of the ideas are up-to-the-mark, they may not
                announce any winning team at the finale.</li>
                <li>Amount of prize money to be awarded to 1st position holder is Rs.1,00,000/-, 2nd position holder is Rs.50,000/- and 3rd position holder is Rs.25,000/- per problem
                statement.</li>
                <li>If there is a tie between two or more teams, the final decision of the prize money distribution will be taken by the problem statement creator only and once the
                decision is made, it won’t be changed further.</li>
              </ul>
              </p>
              <div className='prize'>
              PRIZES UPTO 25,00,000 /-
              </div>
        </div>
        
        
        <div className='hack' >
          <img src={ele1} alt="pic2" className='hack1'  />
          <img src={ele2} alt="pic3" className='hack2'  />
        </div>
        <img src={grand} alt="pic4" style={{width:'100%',height:'auto'}}  />


    <div className='rules'>
        <div className='pad'>
      <ul>
        <li>If a team is selected for the final Hackathon, members will need to travel to IIT Kanpur.</li>
        <li>Shortlisted teams (2-6 students with team leader & mentors) traveling for the grand finale will be eligible to receive reimbursement for to & from journey via sleeper class
      railway fare. The reimbursement amount will be paid at the grand finale nodal center upon producing copies of train tickets and completing relevant forms. The
      arrangement for booking of train tickets is responsibility of teams themselves or their institutes.</li>
      <li>The nodal center will be arranging for accommodation of teams during the grand finale.</li>
      <li>Each college/institution will issue a stamped photo ID to each member of the teams selected for the finale.</li>
      <li>College ID is mandatory for participating in the finale.</li>
      </ul>
      </div>
    </div>
    <img src={hack} alt="pic2" className='hack' style={{width:'100%',height:'auto',marginTop:'-85px'}}  />

        <div className='footer_top'>
          <button className='but1'>Guideline <i class="fa-solid fa-download fa-lg"></i></button>
          <button className='but2'>Idea-Presentation-Format <i class="fa-solid fa-download fa-lg"></i></button>
          <button className='but3'>Project-Submission-Format <i class="fa-solid fa-download fa-lg"></i></button>
        </div>
        <div className='footer'>
        <div className='f1'><span>ABOUT US</span>
        <span className='con'>CONNECT</span>
        </div>
        <div className='f2'><span>PARTNERSHIP</span>
        <span className='icons'>
        <span><i class="fa-brands fa-linkedin fa-2xl "></i></span>
        <span><i class="fa-brands fa-square-instagram fa-2xl"></i></span>
        <span><i class="fa-brands fa-facebook fa-2xl"></i></span>
        <span><i class="fa-brands fa-twitter fa-2xl"></i></span>
        </span>
        </div>
        <div className='copy'>&#169;2023 BIH, Inc. All rights reserved.<img src={end}></img>
        </div>
        </div> */}

       
    </div>
    

  // 145 107 243
  // 4 34 65 
  // 133 94 204
  
  );
}

export default App;
