import React from 'react'
import CollapsibleExample from './navbar';
import pic1 from './images/replace1.webp'
import line from './images/suslogo.webp'
import cons from './images/1.png'
import food from './images/3.png'
import tech from './images/4.png'
import fas from './images/2.png'
import tour from './images/5.png'
import ener from './images/6.png'
import mode_of5 from './images/mode_of5.webp'
import mode_of6 from './images/o2.webp'
import hack from './images/L4.webp'
import ele1 from './images/nn.webp'
import ele2 from './images/ele2.webp'
import grand from './images/grand_finale.webp'
import end from './images/end.png'
export default function Home() {
  return (
      <>
    <CollapsibleExample/>
    <img src={pic1} alt="pic1" style={{width:'100%',height:'auto'}} />

      
      <div className='sus'>
        <div className='left' >
        <img src={line} alt="" srcset="" style={{height:'280px'}}></img>
        </div>
          <div className='right'>
            <h2 style={{marginTop:'30px'}}>HACK THE CLIMATE</h2>
            <p style={{textAlign:'center'}}>
                      BuzzOnEarth India Hackathon -2023 welcomes participation from colleges from all across
                the country to find solutions to some of the most pressing climate and sustainability issues
                that Indian businesses and communities face. The solutions can be combinations of tech
                products, policy evaluations, market models, business plans, new designs and product
                innovations. Give wings to your creativity and ideas and develop innovative solutions that
                can be deployed. This is an Open Innovation Student category in which students can give
                ideas against the pre-defined Problem Statements across themes.
            </p>
          </div>
    </div>


    <h1 className='fade_themes'>
      THEMES
    </h1>
              <h1 className='themes'>
                THEMES
              </h1>
        
      <div className="imggal">
          <div className='left_div'>
          <div class="gallery dot_bo" >
          
          <img src={cons} alt="" srcset="" />
        
          <div class="desc">CONSTRUCTION AND INFRASTRUCTURE</div>
        </div>

        <div class="gallery dot_bo ">
          
          <img src={food} alt="" srcset=""/>

          <div class="desc">FOOD AND AGRICULTURE</div>
        </div>

        <div class="gallery dot_bo">
          
          <img src={tech} alt="" srcset="" />
          
          <div class="desc">TECHNOLOGY AND IOT</div>
        </div>
          </div>
          
        <div className='right_div'>
          <div class="gallery dot_bo">
            
            <img src={fas} alt="" srcset="" />
          
            <div class="desc">FASHION AND SPORTS</div>
          </div>
          <div class="gallery dot_bo">
            
            <img src={tour} alt="" srcset="" />
            
            <div class="desc">TOURISM AND ENTERTAINMENT</div>
          </div>
          <div class="gallery dot_bo ">
            
            <img src={ener} alt="" srcset="" />
            
            <div class="desc">ENERGY AND MOBILITY</div>
          </div>
          </div>
          </div>
   
      
    
        <div className='reg'>
            <h1>
              REGISTRATION PROCESS
            </h1>
            <p className='how'>
            How do I register for BuzzOnEarth India Hackathon 2023?
            </p>
            <p className='info'>
            Students can directly register themselves. If you are a student, visit https://buzzonearth-climatehackathon.in/ for registration. However, you need to have the authorisation
            letter from your respective colleges.
            </p>
            <h1>
            TEAM FORMATION
          </h1>
          <div className='info1'>
          <ul style={{marginBottom:'20px'}}>
            <li>All team members should be from same college (recommended).</li>
            <li>Inter-college teams are also allowed; provided the selected teams for the grand finale submit authorization letters from their respective colleges.</li>
            <li>Members from different branches are encouraged to form a team.</li>
            <li>Each team should mandatorily comprise of 2-6 members including the team leader.</li>
            <li>Each team must have at least one female team member.</li>
            <li>The team should have multi disciplinary skill sets. That should be well versed with programming skills, a good mix of Mechanical Engineers, Electronic Engineers,
          Product Designers and other disciplines.</li>
          </ul>
          </div>
        </div>
        <div className='mode_of' >
          {/* <img src={mode_of5} alt="pic2" className='mode_of5'  /> */}
          {/* <img src={mode_of6} alt="pic3" className='mode_of6'  /> */}
        </div>
        <div className='prob'>
          <h1>
          PROBLEM STATEMENTS AND PRIZES TO BE WON
              </h1>
              <p className='info'>
              <ul>
                <li>BIH2023 brings you problem statements from leading public and private organizations, union ministries, state governments and NGOs in India.</li>
                <li>There will be Top 3 winning teams for every problem statement posted on the portal. The  prize money will be given by the 
                  collaborating ministry/ industry<br></br> ONLY IF that organization likes the idea of the winning team. If the collaborating ministry/ industry concludes that none of the ideas is up-to-the-mark, they may not<br></br> announce any winning team at the finale.</li>
                <li>Upto 1.5 lakhs of prize money to be awarded to winners of the Hackathon..</li>
                <li>If there is a tie between two or more teams, the final decision on the prize money distribution 
                  will be taken by the problem statement creator only, and once the<br></br> decision is made, it won’t be changed further.</li>
              </ul>
              </p>
              <div className='prize'>
              PRIZES UPTO 1,50,000 /-
              </div>
              {/* <div style={{textAlign:'right',fontSize:'30px',color:'white',letterSpacing:'2px',fontFamily:'arimo'}}>
                FREE TRAVEL AND LODGING
              </div> */}
        </div>
        
        
        <div className='hack' >
          <img src={ele1} alt="pic2" className='hack1'  />
          <img src={ele2} alt="pic3" className='hack2'  />
        </div>
        {/* <img src={grand} alt="pic4" style={{width:'100%',height:'auto'}}  /> */}


    {/* <div className='rules'>
        <div className='pad'>
      <ul>
        <li>If a team is selected for the final Hackathon, members will need to travel to IIT Kanpur.</li>
        <li>Shortlisted teams (2-6 students with team leader & mentors) traveling for the grand finale will be eligible to receive reimbursement for to & from journey via sleeper class
      railway fare. The reimbursement amount will be paid at the grand finale nodal center upon producing copies of train tickets and completing relevant forms. The
      arrangement for booking of train tickets is responsibility of teams themselves or their institutes.</li>
      <li>The nodal center will be arranging for accommodation of teams during the grand finale.</li>
      <li>Each college/institution will issue a stamped photo ID to each member of the teams selected for the finale.</li>
      <li>College ID is mandatory for participating in the finale.</li>
      </ul>
      </div>
    </div> */}
    <img src={hack} alt="pic2" className='hack' style={{width:'100%',height:'auto',}}  />

        <div className='footer_top'>
          <a href='https://docs.google.com/document/d/1hjntIBha1xqpskJ4cQUYy9FHqifpy13n/edit?usp=sharing&ouid=101736839996214250728&rtpof=true&sd=true' target={'_blank'}><button className='but1'>Guidelines <i class="fa-solid fa-download fa-lg"></i></button></a>
          <a href='https://docs.google.com/presentation/d/10U1jjVWkGWF5c7vtXqRkf9pXaaBE-qYv/edit?usp=sharing&ouid=101736839996214250728&rtpof=true&sd=true' target={'_blank'}><button className='but2'>Idea-Presentation-Format <i class="fa-solid fa-download fa-lg"></i></button></a>
          <a href='https://docs.google.com/document/d/1hElpvvFghi539uomGIizapJvxNsW6db9/edit?usp=sharing&ouid=101736839996214250728&rtpof=true&sd=true' target={'_blank'}><button className='but3'>Project-Submission-Format <i class="fa-solid fa-download fa-lg"></i></button></a>
        </div>
        <div className='footer'>
        <div className='f1'><span><a href='/about'>ABOUT US</a></span>
        <span className='con'>CONNECT</span>
        </div>
        <div className='f2'>
        <span className='party'><a href='/part'>PARTNERSHIP</a></span>
        <span className='icons'>
        <span><a href='https://www.linkedin.com/company/buzzonearth/?originalSubdomain=in' target={'_blank'} ><i class="fa-brands fa-linkedin fa-2xl "></i></a></span>
        <span><a href='https://www.instagram.com/buzzonearth/?hl=en' target={'_blank'}><i class="fa-brands fa-square-instagram fa-2xl"></i></a></span>
        <span><a href='https://www.facebook.com/buzzonearth/' target={'_blank'}><i class="fa-brands fa-facebook fa-2xl"></i></a></span>
        <span><a href='https://twitter.com/buzzonearth?lang=en' target={'_blank'}><i class="fa-brands fa-twitter fa-2xl"></i></a></span>
        </span>
        </div>
        <div className='copy' ><a target={'_blank'} style={{textDecoration:'none',color:'black'}} href='https://docs.google.com/document/d/1FAqYHq6PgYmZfKQMbJ70GaE75jBdQrU55dOHn8LXl9Q/edit?usp=sharing'>CHALLENGES</a>
        <img src={end}></img>
        </div>
        </div>
        </>
  )
}
