import React from 'react'
import CollapsibleExample from './navbar'
import about_us from './images/about us.webp'
import end from './images/end.png'
export default function About() {
  return (
      <>
    <CollapsibleExample/>
    <img src={about_us} alt="pic1" style={{width:'100%',height:'auto'}} />
    <div className='footer'>
        <div className='f1'><span><a href='/about'>ABOUT US</a></span>
        <span className='con'>CONNECT</span>
        </div>
        <div className='f2'>
        <span className='party'><a href='/part'>PARTNERSHIP</a></span>
        <span className='icons'>
        <span><a href='https://www.linkedin.com/company/buzzonearth/?originalSubdomain=in' target={'_blank'} ><i class="fa-brands fa-linkedin fa-2xl "></i></a></span>
        <span><a href='https://www.instagram.com/buzzonearth/?hl=en' target={'_blank'}><i class="fa-brands fa-square-instagram fa-2xl"></i></a></span>
        <span><a href='https://www.facebook.com/buzzonearth/' target={'_blank'}><i class="fa-brands fa-facebook fa-2xl"></i></a></span>
        <span><a href='https://twitter.com/buzzonearth?lang=en' target={'_blank'}><i class="fa-brands fa-twitter fa-2xl"></i></a></span>
        </span>
        </div>
        <div className='copy'><a target={'_blank'} style={{textDecoration:'none',color:'black'}} href='https://docs.google.com/document/d/1FAqYHq6PgYmZfKQMbJ70GaE75jBdQrU55dOHn8LXl9Q/edit?usp=sharing'>CHALLENGES</a><img src={end}></img>
        </div>
        </div>
    </>
    
  )
}
